@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Montserrat", sans-serif;
    background-color: #f3e5f5;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.test{
    background-color: #f89e28;
}

a {
    text-decoration: none;
    color: black;
}


.sticky-column {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

.current_quantity{
    color: red;
}


.only_view{
    background-color: #f1f1f1;
    color:#1a3e72;
}